@include font('Cormorant-LightItalic', 'Cormorant-LightItalic');
@include font('Cormorant-Light', 'Cormorant-Light');
@include font('Cormorant-Regular', 'Cormorant-Regular');

@include font('Montserrat-Bold', 'Montserrat-Bold');
@include font('Montserrat-Regular', 'Montserrat-Regular');
@include font('Montserrat-Light', 'Montserrat-Light');

@include font('PTSerif-Italic', 'PTSerif-Italic');

@include font('HomemadeApple', 'HomemadeApple');

@mixin reg {
  font-family: 'Cormorant-Regular', serif; }

@mixin light {
  font-family: 'Cormorant-Light', serif; }

@mixin light-italic {
  font-family: 'Cormorant-LightItalic', serif; }

@mixin mont-bold {
  font-family: 'Montserrat-Bold', sans-serif; }

@mixin mont-req {
  font-family: 'Montserrat-Regular', sans-serif; }

@mixin mont-light {
  font-family: 'Montserrat-Light', sans-serif; }

@mixin pt-italic {
  font-family: 'PTSerif-Italic', serif; }

@mixin apple {
  font-family: 'HomemadeApple', sans-serif; }
