.icon-360-map {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-360-section {
  width: 1.45em;
  height: 1em;
  fill: initial;
}
.icon-360 {
  width: 1.07em;
  height: 1em;
  fill: initial;
}
.icon-pause {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-play {
  width: 1em;
  height: 1em;
  fill: initial;
}
