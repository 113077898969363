.just-say {
  height: 100%;
  width: 100%;

  &__in {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: inherit;
    padding: $x;
    position: relative;
    text-align: center;
    justify-content: center; }

  &__block {
    font-size: 2rem;
    line-height: 1.2;
    letter-spacing: 0.1em;
    @include mont-light;

    p {
      margin-bottom: 2rem; } }

  &__tag {
    color: #C4C4C4;
    display: inline-block;
    font-size: 4.8rem;
    position: relative;
    line-height: 1.2;
    letter-spacing: 0.02em;
    text-decoration: none;
    text-transform: lowercase;
    @include mont-bold;

    &:before {
      @include pseudo;
      background-color: #ffffff;
      bottom: -0.1em;
      height: 1px;
      opacity: 0.3;
      top: auto;
      width: 100%; } }

  &__btn,
  &__link {
    background-color: transparent;
    color: rgba(255, 255, 255, 0.3);
    font-size: 1.8rem;
    letter-spacing: 0.08em;
    position: absolute;
    right: $x;
    text-decoration: none;
    white-space: nowrap;
    @include mont-req; }

  &__btn {
    top: $y; }

  &__link {
    top: 50%;
    transform: translateY(-50%); } }



