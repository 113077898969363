.gallery {
  $this: &;
  padding: 0 25vw 0 240px;
  // padding: 0 240px
  will-change: transform;

  &__in {
    position: relative; }

  &__row {
    align-items: center;
    display: flex;

    &_back {
      display: flex;
      height: 100%;
      left: -200px;
      opacity: 0;
      position: absolute;
      top: 0;
      justify-content: space-between;
      width: 50%;

      img {
        max-height: 457px;
        max-width: 350px;

        &:nth-of-type(2n) {
          transform: translateY(50%); }

        &:nth-of-type(2n + 1) {
          transform: translateY(-50%); } } } }

  &__item {
    cursor: default;
    font-size: 18px;
    position: relative;
    @include mont-req;

    &:first-of-type {
      margin-left: 0; }

    img {
      display: block;
      height: auto;
      max-height: 60vh; }

    &_padding {
      &-lg {
        padding: 0 130px;

        #{$this}__text {
          left: calc(100% - 50px); } } }

    &_image {
      & + #{$this}__item_date {
        // margin-left: 130px
        margin-left: 217px; }

      img {
        opacity: 0; } }

    &_audio {
      margin-left: 340px;

      & + #{$this}__item_image {
        margin-left: 340px; } }

    &_date {
      margin-left: 268px;

      & + #{$this}__item_audio {
        margin-left: 268px; }

      & + #{$this}__item_object {
        margin-left: 200px; }

      & + #{$this}__item_image {
        margin-left: 135px; } }

    &_object {
      margin-left: 340px;

      & + #{$this}__item_date {
        margin-left: 356px; }

      #{$this} {
        &__text {
          left: 120px;
          margin-top: 33px;
          // position: relative
          width: 380px;
          top: calc(100% + 20px);

          &:before {
            bottom: -40px;
            border: 1px solid rgba(#E1DADA, 0.4);
            content: '';
            height: 1px;
            left: -50px;
            position: absolute;
            transform: rotate(45deg);
            transform-origin: top left;
            width: 80px; } } } } }


  &__headline {
    padding-top: 10px;
    padding-left: 45px;
    position: relative;

    &:before {
      border: 1px solid rgba(#E1DADA, 0.4);
      content: '';
      left: 10px;
      position: absolute;
      top: 30px;
      transform: rotate(-45deg);
      transform-origin: top left;
      width: 70px; }

    &_pos {
      &_top {
        margin-bottom: 28px; }

      &_bottom {
        margin-top: 28px;

        &:before {
          bottom: 30px;
          top: auto;
          left: -10px;
          transform: rotate(45deg); } } } }

  &__title {
    font-size: 1.4rem;
    line-height: 1.2;
    margin-bottom: 5px;
    @include apple;

    img {
      display: block;
      height: 4.4rem;
      opacity: 1; } }

  &__text {
    bottom: 0;
    color: #C4C4C4;
    line-height: 1.4;
    letter-spacing: 0.02em;
    left: calc(100% + 20px);
    max-width: 480px;
    padding-bottom: 30px;
    position: absolute;
    width: calc(104% + 20px);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.8s, visibility 0.8s;

    &_sm {
      max-width: 387px; }

    &_md {
      max-width: 470px; }

    &_bottom {
      left: 50%;
      padding-top: 10px;
      position: relative;
      width: calc(59% - 10px); }

    &_top {
      bottom: auto;
      top: 0; }

    &_right {
      display: flex;
      flex-direction: column;
      max-height: 60vh;
      top: 0;
      justify-content: flex-end; }

    p {
      &:not(:last-of-type) {
        margin-bottom: 20px; } } }

  &__btn {
    background-color: transparent;
    display: block;
    height: 28px;
    position: relative;
    width: 28px;

    &,
    &:before {
      transition: transform 0.3s; }

    &:hover {
      transform: scale(2);

      &:before {
        animation: none;
        transform: translate(-50%, -50%) scale(0.8); } }

    &:before {
      animation: pulse 1.5s ease-out alternate running infinite;
      border: 1px solid rgba(#DAD2D1, 0.2);
      border-radius: 50%;
      content: '';
      height: 100%;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%) scale(2);
      width: 101%; }

    svg {
      fill: #DCD2D1;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      transition: visibility 0.3s, opacity 0.3s;
      visibility: visible;
      width: 100%;

      &:nth-of-type(2) {
        opacity: 0;
        visibility: hidden; } }

    &.is-playing {
      svg {
        &:nth-of-type(1) {
          opacity: 0;
          visibility: hidden; }

        &:nth-of-type(2) {
          opacity: 1;
          visibility: visible; } } } }

  &__date {
    padding-top: 5px;
    @include light-italic;

    &:before {
      background-color: #C4C4C4;
      content: '';
      left: 50%;
      position: absolute;
      top: 0;
      transform: translate(-50%, -50%); }

    &_year {
      &:before {
        border-radius: 50%;
        height: 5px;
        width: 5px; } }

    &_month {
      &:before {
        height: 1px;
        transform: translateX(-50%) rotate(-45deg);
        width: 10px; } } }

  &__photo {
    cursor: pointer;

    &:hover {
      & ~ #{$this}__text {
        opacity: 1;
        visibility: visible; } } }

  &__dot {
    display: block;
    right: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%); }

  &__parallax-item {
    max-width: 350px;

    img {
      width: 100%; } } }

@keyframes pulse {
  0%, 50% {
    transform: translate(-50%, -50%) scale(1); }
  100% {
    transform: translate(-50%, -50%) scale(2); } }
