.final {
  font-size: 2.4rem;
  line-height: 1.2;
  width: 100%;
  @include mont-req;

  &__text {
    padding-left: 36%;
    position: relative;
    top: -0.5em;
    transform: translateY(50%); }

  &__line {
    background-color: #fff;
    height: 2px;
    left: 0;
    opacity: 0.4;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transform-origin: top left;
    width: calc(36% - 80px); }

  p {
    &:not(:last-of-type) {
      margin-bottom: 15px; }

    &:nth-of-type(2) {
      color: inherit;
      font-size: 3.2rem;
      text-decoration: none;
      @include light-italic; } } }
