.son {
  $this: &;
  height: 100%;
  padding: $x;
  position: relative;
  width: 100%;
  @include mont-req;

  &__in {
    height: inherit;
    display: flex;
    justify-content: space-between;
    width: inherit; }

  &__col {
    position: relative;
    min-width: 51%;
    width: 51%;

    &:nth-of-type(2) {
      align-items: center; } }

  &__photo {
    align-items: center;
    display: flex;
    height: 80%;
    left: 5%;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    top: 10%;
    justify-content: center;
    visibility: hidden;
    width: 90%;

    img {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      object-fit: cover; } }

  &__item {
    height: 100%;
    font-size: 40px;
    line-height: 1.2;
    letter-spacing: 0.06em;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;

    img {
      height: 100%;
      display: block;
      left: 50%;
      max-height: 100%;
      max-width: 100%;
      object-fit: contain;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%); }

    &_align-center {
      cursor: pointer;
      height: auto;
      text-align: center;
      top: 50%;
      transform: translateY(-50%); } }

  &__text {
    font-size: 2.2rem;
    line-height: 1.5;
    left: 14vw;
    max-width: calc(620px + 14vw);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;

    &:first-of-type {
      font-size: 1.9rem;
      line-height: 1.3em;
      letter-spacing: 0.04em;
      @include pt-italic; }

    &_hidden {
      opacity: 0;
      visibility: hidden; } }

  &__title {
    color: #D8D1D1;
    font-size: 4.8rem;
    line-height: 1.2;
    letter-spacing: 0.015em;
    margin-bottom: 6rem;
    @include light-italic; }

  &__dot {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 0;

    &:nth-child(2) {
      left: 99.8%; } }

  &__item,
  &__text,
  &__photo {
    transition: opacity 0.6s, visibility 0.6s; }

  &.is-first-section-played {
    #{$this} {
      &__item,
      &__text,
      &__photo {
        transition: none; } } }

  &__item,
  &__text #{$this}__paragraphs {
    &:not(:first-child) {
      opacity: 0;
      visibility: hidden; } }

  &__title {
    opacity: 0;
    visibility: hidden; }

  &__in {
    &.is-hovered {

      #{$this}__photo {
        opacity: 1;
        visibility: visible; } } } }
