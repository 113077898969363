.video-360 {
  height: 100vh;
  height: calc(var(--vh, 1) * 100);
  width: 100vw;
  width: calc(var(--vw, 1) * 100);

  &__icon {
    height: 114px;
    left: $x;
    position: absolute;
    top: 140px;
    width: 114px;

    svg {
      display: block;
      fill: #F2E7E7;
      height: auto;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 100%; }

    &_position {
      bottom: $y;
      height: 74px;
      top: auto;
      width: 74px;

      svg {
        &:nth-child(2) {
          height: 51%;
          top: -2px;
          left: -1px;
          transform: translate(0, 0) rotate(180deg);
          transform-origin: bottom center; } } } }

  .video-js {
    height: 100%;
    left: 0;
    position: absolute;
    top: -1px;
    width: 100%; } }
