.whats-next {
  $this: &;
  height: 100vh;
  height: calc(var(--vh, 1) * 100);
  left: 0;
  overflow: hidden;
  opacity: 0;
  position: fixed;
  top: 0;
  transform: translateX(100%);
  transition: transform linear 0.6s;
  visibility: hidden;
  width: 100vw;
  width: calc(var(--vw, 1) * 100);
  z-index: 1000;

  &:before {
    @include pseudo;
    background-color: rgba(255, 255, 255, 0.5);
    height: 1px;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 100%; }

  &__in {
    height: 100%;
    padding: $x; }

  &__row {
    height: 50%;
    padding: 30px 0;
    position: relative;

    &_top {
      align-items: flex-end;
      display: flex;
      justify-content: space-between; } }

  &__item {
    cursor: pointer;
    position: relative;

    &.is-active {
      #{$this}__index,
      #{$this}__title {
        opacity: 1; } } }

  &__title {
    font-size: 3.6rem;
    font-weight: 600;
    line-height: 1.2;
    letter-spacing: 0.055em;
    opacity: 0.5;
    @include mont-req; }

  &__index {
    bottom: 0.8em;
    color: #ffffff;
    font-size: 1.8rem;
    left: 0;
    line-height: 0;
    opacity: 0.3;
    position: absolute;
    transform: translate(-100%, -50%);
    white-space: nowrap;
    width: 7rem;
    @include mont-light; }

  &__textual {
    color: #C4C4C4;
    font-size: 2.2rem;
    line-height: 1.5;
    opacity: 0;
    padding-top: 3vh;
    padding-left: 5vw;
    position: absolute;
    visibility: hidden;
    width: 100%;
    @include mont-req;

    &.is-active {
      opacity: 1;
      visibility: visible;
      z-index: 1; }

    p {
      max-width: 62%; }

    a {
      color: inherit; }

    &:nth-of-type(1),
    &:nth-of-type(3) {
      width: 90%; } }

  &__item,
  &__textual {
    transition: opacity 0.6s, visibility 0.6s; }

  &__btn {
    background-color: transparent;
    color: #DBD5D5;
    font-size: 1.8rem;
    line-height: 1.2;
    letter-spacing: 0.1em;
    left: $x;
    position: absolute;
    top: $y;
    @include mont-light; }

  .is-modal-visible & {
    transform: translateX(0); } }

