.preloader {
  align-items: center;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  justify-content: center;
  visibility: hidden;
  transition: visibility 1s;
  width: 100%;

  &__in {
    display: inline-flex; }

  &__item {
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    height: 25vh;
    height: calc(var(--vh, 1) * 25);
    max-height: 196px;
    margin: auto;
    opacity: 0.4;
    transition: opacity 1s;

    svg {
      display: block;
      height: 100%; }

    path {
      stroke-dasharray: 1400;
      animation: draw 2s; }

    &.is-hidden {
      opacity: 0; } }

  &[data-num="00"] {
    [data-item="00"] {
      display: block; } }

  &[data-num="10"] {
    [data-item="10"] {
      display: block; } }

  &[data-num="20"] {
    [data-item="20"] {
      display: block; } }

  &[data-num="30"] {
    [data-item="30"] {
      display: block; } }

  &[data-num="40"] {
    [data-item="40"] {
      display: block; } }

  &[data-num="50"] {
    [data-item="50"] {
      display: block; } }

  &[data-num="60"] {
    [data-item="60"] {
      display: block; } }

  &[data-num="70"] {
    [data-item="70"] {
      display: block; } }

  &[data-num="80"] {
    [data-item="80"] {
      display: block; } }

  &[data-num="90"] {
    [data-item="90"] {
      display: block; } }

  &[data-num="100"] {
    [data-item="100"] {
      display: block; } }

  [data-item] {
    display: none; } }

@keyframes draw {
  0% {
    stroke-dashoffset: 2750; }
  80%, 100% {
    stroke-dashoffset: 2800; } }
