.history {
  display: flex;
  position: relative;
  width: 100%;

  &__in {
    height: 100vh;
    padding: 300px 0 500px;
    padding-right: 50px;
    position: relative;
    width: 100%; }

  &__text {
    color: #D8D1D1;
    font-size: 3.6rem;
    line-height: 1.14em;
    letter-spacing: -0.005em;
    padding-bottom: 100vh; }

  &__item {
    position: relative;
    left: 50%;
    width: 50%; }

  &__title {
    font-size: 3rem;
    font-weight: normal;
    left: -68px;
    letter-spacing: 0.015em;
    position: absolute;
    top: 0;
    transform: translateX(-100%);
    text-align: right;
    width: calc(100% - 68px);
    @include mont-req; }

  &__dot {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;

    &:nth-of-type(1) {
      top: 50vh; }

    &:nth-of-type(2) {
      top: 45vh;
      left: 10vw; }

    &:nth-of-type(3) {
      top: 67vh;
      left: 30vw; }

    &:nth-of-type(4) {
      top: 46vh;
      left: 50vw; }

    &:nth-of-type(5) {
      left: 100vw;
      left: calc(var(--vw, 1) * 99);
      top: 50vh;
      top: calc(var(--vh, 1) * 50); } }

  &__dates {
    color: #F8EFEF;
    font-size: 1.8rem;
    line-height: 1.6em;
    opacity: 0.6;
    @include light-italic; }

  &__date {
    position: absolute;
    opacity: 0;
    visibility: hidden;

    &:before {
      @include pseudo;
      background-color: rgba(223, 217, 217, 0.5);
      height: 2px;
      top: -1.75vh;
      transform: translateX(50%) rotate(45deg);
      width: 20px; }

    &:nth-child(1) {
      left: 6vw;
      top: 46vh; }

    &:nth-child(2) {
      left: 29vw;
      top: 61vh;

      &:before {
        bottom: -2.6vh;
        top: auto; } } }

  .ps__rail-x,
  .ps__rail-y {
    opacity: 0;
    visibility: hidden; } }
