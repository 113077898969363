.breakpoint {
  color: #D8D1D1;
  font-size: 2.4rem;
  line-height: 1.2em;
  letter-spacing: 0.015em;
  text-align: center;

  p {
    &:not(:last-of-type) {
      margin-bottom: 50px;
      @include light; }

    &:last-of-type {
      font-size: 1.8rem;
      line-height: 1.2em;
      letter-spacing: 0;
      @include mont-req; } }

  &__point {
    display: inline-block;
    left: 0;
    position: absolute;
    top: 0;

    &:nth-child(1) {
      left: 0;
      top: 50%; }

    &:nth-child(2) {
      left: 50%;
      top: 50%; }

    &:nth-child(3) {
      left: 80%;
      top: 60%; }

    &:nth-child(4) {
      left: 99.8%;
      top: 50%; } } }
