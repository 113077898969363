.isolation {
  $this: &;
  height: 100%;
  width: 100%;

  &__in {
    position: relative;
    height: 100%;
    width: 100%;

    &.has-background {
      background: linear-gradient(to right, rgba(0,0,0,0.3) 0%, rgba(255,255,255,0) 50%, rgba(0,0,0,0.3) 100%); } }

  &__photo {
    left: 52%;
    margin-left: auto;
    max-width: 442px;
    position: absolute;
    top: 50%;
    transform-origin: top;
    width: 25%; }

  &__box {
    height: 100%;
    left: 50%;
    opacity: 0;
    position: absolute;
    top: 0;
    transition: opacity 1s, transform 1s;
    width: 1000%;
    will-change: opacity, transform;

    &_before {
      background: linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0.3) 100%);
      clip-path: polygon(0% 0%, 100% 0%, 69% 100%, 61% 100%);
      -webkit-clip-path: polygon(0% 0%, 100% 0%, 69% 100%, 61% 100%);
      transform: translate(-80%, -140%); }

    &_after {
      background: linear-gradient(to bottom, rgba(0,0,0,0.3) 0%, rgba(0,0,0,0) 100%);
      top: 100%;
      transform: translate(-80%, -60%);
      clip-path: polygon(61% 0%, 69% 0%, 100% 100%, 0% 100%);
      -webkit-clip-path: polygon(61% 0%, 69% 0%, 100% 100%, 0% 100%); } }

  &__image {
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
    -webkit-clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
    display: block;
    position: relative;
    transform: scale(1.1) translate(-50%, -50%);
    width: 100%;
    will-change: transform, clip-path;
    z-index: 1; }

  &__fears {
    height: 100%;
    position: relative;
    width: 100%;
    z-index: 1; }

  &__fear {
    &:nth-of-type(1) {
      #{$this}__item {
        &:nth-child(1) {
          left: 10%;
          top: 47%;
          transform: translateY(-50%); } } }

    &:nth-of-type(2) {
      #{$this}__item {
        &:nth-child(1) {
          left: 10%;
          top: 60%;
          transform: translateY(-50%);
          width: 40%; } } }

    &:nth-of-type(3) {
        #{$this}__item {
          &:nth-child(1) {
            left: 15%;
            top: 80%;
            transform: translateY(-50%);
            width: 30%; }

          &:nth-child(2) {
            left: 65%;
            top: 20%;
            transform: translateY(-50%);
            width: 28%; }

          &:nth-child(3) {
            left: 5%;
            top: 36%;
            transform: translateY(-50%);
            width: 25%; } } }

    &:nth-of-type(4) {
      #{$this}__item {
        &:nth-child(1) {
          left: 15%;
          top: 20%;
          transform: translateY(-50%);
          width: 30%; }

        &:nth-child(2) {
          left: 55%;
          top: 80%;
          transform: translateY(-50%);
          width: 30%; }

        &:nth-child(3) {
          left: 65%;
          top: 40%;
          transform: translateY(-50%);
          width: 20%; }

        &:nth-child(4) {
          bottom: 1%;
          left: 2%;
          top: auto;
          transform: translateY(-50%);
          width: 20%; }

        &:nth-child(5) {
          left: 65%;
          top: 60%;
          transform: translateY(-50%);
          width: 30%; } } }

    &:nth-of-type(5) {
      #{$this}__item {
        &:nth-child(1) {
          left: 10%;
          top: 50%;
          transform: translateY(-50%); } } }

    &_last {
      opacity: 0;
      visibility: hidden;

      #{$this}__item {
        opacity: 1;
        visibility: visible; } } }

  &__item {
    opacity: 0;
    position: absolute;
    visibility: hidden;
    width: 25%; }

  &__dot {
    display: block;
    left: 55%;
    opacity: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    &:nth-child(2) {
      left: calc(var(--vw, 1) * 120); } } }
