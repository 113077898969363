.trying {
  $this: &;
  height: 100%;
  position: relative;
  width: 100%;

  &__block {
    left: 50%;
    opacity: 0;
    position: absolute;
    top: calc(100vh * 0.43);
    transform: translate(-50%, 0);
    visibility: hidden;
    width: calc(100vw * 0.50);

    &:nth-child(2) {
      top: calc(100vh * 0.42);

      #{$this}__headline {
        left: calc(100vw * 0.09);
        width: calc(100vw * 0.37); } }

    &:nth-child(3) {
      #{$this}__headline {
        width: calc(100vw * 0.36); } }

    &:last-child {
      #{$this}__headline {
        transform: translateY(125%); } } }

  &__headline {
    left: calc(100vw * 0.08);
    position: relative;
    width: calc(100vw * 0.23);

    svg {
      width: 100%; } }

  &__text {
    color: #DCDBDB;
    font-size: 2.2rem;
    line-height: 1.5em;
    left: 30.6%;
    padding-top: 5rem;
    position: absolute;
    top: calc(100% + 30px);
    width: 100%;
    @include mont-light; } }

