.intro {
  $this: &;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  user-select: none;
  visibility: hidden;
  width: 100%;

  &__in {
    display: flex;
    height: 100%;
    padding: $y 0;
    transition: transform 1.2s; }

  &__row {
    align-items: center;
    display: flex;
    height: 100%;
    position: relative;
    justify-content: space-between; }

  &__row,
  &__choose {
    flex-grow: 0;
    flex-shrink: 0;
    width: 100vw;
    width: calc(var(--vw, 1) * 100); }

  &__col {
    flex-grow: 1;
    flex-shrink: 1;
    width: 50%;

    & > #{$this}__row {
      width: 100%;

      #{$this}__col {
        width: 25%; } } }

  &__start {
    #{$this} {
      &__col {
        &:first-of-type {
          width: 65%; }

        &:last-of-type {
          left: -6%;
          position: relative;
          width: 50%; } }

      &__text {
        &:before {
          background-color: #ffffff;
          content: '';
          height: 2px;
          position: absolute;
          right: -100%;
          opacity: 0.22;
          top: 50%;
          transition: width 0.7s 0.3s;
          transform-origin: top left;
          width: 100%; } } } }

  &__logo {
    left: -5%;
    position: relative;
    transform: translateY(-5%);

    svg {
      display: block;
      opacity: 0.8;
      width: 100%;

      path {
        stroke-dashoffset: 100;

        &:nth-child(1) {
          stroke-dasharray: 1550; }

        &:nth-child(2) {
          stroke-dasharray: 362; }

        &:nth-child(3) {
          stroke-dasharray: 986; }

        &:nth-child(4) {
          stroke-dasharray: 319; }

        &:nth-child(5) {
          stroke-dasharray: 1227; }

        &:nth-child(6) {
          stroke-dasharray: 981; }

        &:nth-child(7) {
          stroke-dasharray: 336; }

        &:nth-child(8) {
          stroke-dasharray: 266; }

        &:nth-child(9) {
          stroke-dasharray: 1955; } } }

    &.is-animating {
      path {
        animation: draw-logo 3s forwards; } } }

  &__title {
    color: #E1DCD6;
    font-size: 4.5rem;
    letter-spacing: 0.09em;
    left: 100%;
    position: absolute;
    top: 50%;
    transform: translate(-20%, -20%);
    @include mont-bold; }

  &__btn {
    background-color: transparent;
    color: #ffffff;
    font-size: 1.8rem;
    letter-spacing: 0.1em;
    position: fixed;
    top: $y;
    z-index: 1; }

  &__btn,
  &__text {
    right: $x; }

  &__text {
    display: block;
    font-size: 1.9rem;
    position: absolute;
    right: 0;
    top: 50%;
    text-align: left;
    transform: translateY(-50%);
    @include pt-italic;

    &_call-btn {
      cursor: pointer;

      &:before {
        animation: line 2s infinite forwards ease; } }

    &.is-active {
      &:before {
        padding-left: 0.8vw;
        width: 5vw; } }

    .is-first-screen-played & {
      &:before {
        animation: none;
        opacity: 0;
        width: 50%; } } }

  &__choose {
    text-align: center;

    #{$this}__row {
      height: 100%; }

    #{$this}__text {
      opacity: 0;
      top: 50%;
      transform: translateY(-50%);
      transition: opacity 0.6s, visibility 0.6s;
      visibility: hidden;

      &:nth-child(1) {
        opacity: 1;
        visibility: visible; }

      .is-promo-visible & {
        opacity: 1;
        visibility: visible; }

      // [data-history="first"] &
      //   &:nth-child(2)
      //     opacity: 1
 } }      //     visibility: visible

  &__link {
    color: #D8D1D1;
    font-size: 4rem;
    display: inline-block;
    letter-spacing: 0.06em;
    opacity: 1;
    position: relative;
    text-decoration: none;
    visibility: visible; }

  &__photo {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 80%;
    left: 5%;
    opacity: 0;
    position: fixed;
    top: 10%;
    transition: opacity 0.8s, visibility 0.8s;
    visibility: hidden;
    width: 90%;

    .is-promo-visible & {
      opacity: 1;
      visibility: visible; }

    [data-history="first"] & {
      background-image: url('/img/mom-1.png'); } }

  .is-first-screen-played & {
    &__in {
      transform: translateX(-100%); }

    // &__text
    //// &:before
 } }    //  // opacity: 0

.intro-section-name {
  $this: &;
  position: relative;

  &__txt {
    font-size: 4rem;
    line-height: 1.2em;
    letter-spacing: 0.06em;
    @include mont-req; }

  &__img {
    animation: poly-reversed 1s;
    bottom: 0;
    height: 1.4em;
    left: 50%;
    opacity: 0;
    position: absolute;
    transform: translateX(-50%);
    transition: clip-path 0.8s;
    visibility: hidden;

    img {
      height: 100%;
      display: block; } }

  &__txt {
    opacity: 1;
    visibility: visible; }

  &__txt,
  &__img {
    transition: visibility 0.8s, opacity 0.8s; }

  &:hover {
    #{$this} {
      &__img {
        animation: poly 1s;
        opacity: 1;
        visibility: visible; }

      &__txt {
        opacity: 0;
        visibility: hidden; } } } }

@keyframes poly {
  0% {
    clip-path: polygon(0 0, 0 0, 0 0, 0% 100%);
    -webkit-clip-path: polygon(0 0, 0 0, 0 0, 0% 100%); }
  100% {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%); } }

@keyframes poly-reversed {
  0% {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%); }
  100% {
    clip-path: polygon(0 0, 0 0, 0 0, 0% 100%);
    -webkit-clip-path: polygon(0 0, 0 0, 0 0, 0% 100%); } }

@keyframes line {
  0% {
    transform: scaleX(0);
    transform-origin: top left; }
  50% {
    transform: scaleX(1);
    transform-origin: top left; }
  51% {
    transform: scaleX(1);
    transform-origin: top right; }
  100% {
    transform: scaleX(0);
    transform-origin: top right; } }

@keyframes draw-logo {
  0% {
    stroke-dashoffset: 100; }
  80%, 100% {
    stroke-dashoffset: 0; } }
